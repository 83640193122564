import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e85d17ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "task-pipeline-doughnut-wrapper" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Doughnut = _resolveComponent("Doughnut")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Doughnut, {
      options: $data.options,
      data: $data.chartData,
      id: $props.chartId,
      plugins: [ $data.innerLabel ]
    }, null, 8 /* PROPS */, ["options", "data", "id", "plugins"])
  ]))
}