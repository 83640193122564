import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1f6d8d38"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rudolf-qr-card-scan-topnav-area" }
const _hoisted_2 = { class: "rudolf-qr-card-scan-container" }
const _hoisted_3 = { class: "qr-card-scan-camera-container" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 2,
  class: "camera-status-box"
}
const _hoisted_6 = {
  key: 3,
  class: "camera-status-box"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_qrcode_stream = _resolveComponent("qrcode-stream")
  const _component_b_loading = _resolveComponent("b-loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "rudolf-qr-card-scan-close",
        onClick: _cache[0] || (_cache[0] = $event => ($options.toggleQrCardScanner(false)))
      }, [
        _createVNode(_component_b_icon, {
          class: "rudolf-mobile-menu-close",
          icon: "close",
          size: "is-large"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        (!$data.cameraError)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.l('qr-code-scanner/top-text')), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        ($data.toggled)
          ? (_openBlock(), _createBlock(_component_qrcode_stream, {
              key: 1,
              onDetect: $options.onDetect,
              onError: $options.onError,
              onCameraOn: $options.onCameraOn
            }, null, 8 /* PROPS */, ["onDetect", "onError", "onCameraOn"]))
          : _createCommentVNode("v-if", true),
        ($data.cameraLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_b_loading, {
                "is-full-page": false,
                modelValue: $data.cameraLoading,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.cameraLoading) = $event)),
                animation: "none"
              }, null, 8 /* PROPS */, ["modelValue"])
            ]))
          : _createCommentVNode("v-if", true),
        ($data.cameraError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString($data.cameraError), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_b_loading, {
          "is-full-page": false,
          modelValue: $data.qrCodeProcessing,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.qrCodeProcessing) = $event)),
          animation: "none"
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _createElementVNode("div", {
      class: "rudolf-qr-card-scan-button",
      onClick: _cache[3] || (_cache[3] = $event => ($options.toggleQrCardScanner(true)))
    })
  ]))
}