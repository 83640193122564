import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-729a8941"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "chart-bottom-title-text"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Bar = _resolveComponent("Bar")

  return (_openBlock(), _createElementBlock("div", {
    class: "yearly-chart-container",
    style: _normalizeStyle({ width: $props.type === 'minimal' ? '135px' : $props.autoWidth ? 'auto' : '250px' })
  }, [
    _createElementVNode("div", {
      class: "yearly-chart-wrapper",
      style: _normalizeStyle({ height: $options.getChartHeight() })
    }, [
      _createVNode(_component_Bar, {
        options: $data.options,
        data: $data.chartData,
        id: $props.chartId,
        "dataset-id-key": $props.datasetIdKey
      }, null, 8 /* PROPS */, ["options", "data", "id", "dataset-id-key"])
    ], 4 /* STYLE */),
    ($props.type !== "minimal" && $props.subtitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.l('kpi/yearly-target-view')), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ], 4 /* STYLE */))
}