import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a37a5c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "topnav-buttons" }
const _hoisted_2 = { class: "advanced-filter-tool is-hidden-mobile" }
const _hoisted_3 = {
  key: 0,
  class: "scan-qr-card is-hidden-tablet"
}
const _hoisted_4 = { class: "rnotifications" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_advanced_filter_tool = _resolveComponent("advanced-filter-tool")
  const _component_qr_card_scan = _resolveComponent("qr-card-scan")
  const _component_notifications = _resolveComponent("notifications")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_advanced_filter_tool)
    ], 512 /* NEED_PATCH */), [
      [_vShow, ["manage-leads", "overview"].includes(_ctx.rootStore.currentPage)]
    ]),
    (_ctx.authStore.isAdmin)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_qr_card_scan)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_notifications)
    ])
  ]))
}