import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dc80667"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "table-settings",
  id: "table-settings"
}
const _hoisted_2 = { class: "table-settings-top" }
const _hoisted_3 = { class: "is-flex-grow-1" }
const _hoisted_4 = { class: "rtitle sm normalcase" }
const _hoisted_5 = { class: "table-settings-middle" }
const _hoisted_6 = { class: "column-selector-sections" }
const _hoisted_7 = { class: "select-all-clear-all" }
const _hoisted_8 = { class: "select-all" }
const _hoisted_9 = { class: "clear-all" }
const _hoisted_10 = { class: "column-select-lead-details" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "category-name" }
const _hoisted_13 = { class: "category-arrow" }
const _hoisted_14 = { style: {"margin-bottom":"10px"} }
const _hoisted_15 = { class: "rtitle sm normalcase mb-3" }
const _hoisted_16 = { class: "column-select-lead-questions-products" }
const _hoisted_17 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = {
  key: 0,
  class: "column-select-lead-questions"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "category-name" }
const _hoisted_22 = { class: "category-arrow" }
const _hoisted_23 = { style: {"margin-bottom":"10px"} }
const _hoisted_24 = { class: "table-settings-bottom" }
const _hoisted_25 = { class: "buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_checkbox = _resolveComponent("b-checkbox")
  const _component_b_field = _resolveComponent("b-field")
  const _component_Collapse = _resolveComponent("Collapse")
  const _component_b_tab_item = _resolveComponent("b-tab-item")
  const _component_b_tabs = _resolveComponent("b-tabs")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.l('manage-leads-table-settings/title')), 1 /* TEXT */)
      ]),
      _createElementVNode("div", {
        class: "is-clickable",
        onClick: _cache[0] || (_cache[0] = $event => ($options.close()))
      }, [
        _createVNode(_component_b_icon, { icon: "window-close" })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_b_tabs, { position: "is-centered" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_tab_item, null, {
              header: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.l('manage-leads-table-settings/lead-details-tab')), 1 /* TEXT */)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_b_button, {
                      type: "is-light",
                      rounded: "",
                      onClick: _cache[1] || (_cache[1] = $event => ($options.selectAllColumns()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.l('manage-leads-table-settings/select-all')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_b_button, {
                      type: "is-light",
                      rounded: "",
                      onClick: _cache[2] || (_cache[2] = $event => ($options.clearAllColumnSelections()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.l('manage-leads-table-settings/clear-all')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.columnCategories, (category, idx) => {
                    return (_openBlock(), _createElementBlock(_Fragment, {
                      key: "column_select_category_" + idx
                    }, [
                      _createElementVNode("div", {
                        class: "column-select-category-collapse is-clickable",
                        onClick: $event => (category.collapsed = !category.collapsed)
                      }, [
                        _createElementVNode("div", _hoisted_12, _toDisplayString(category.label) + " (" + _toDisplayString($options.numColumnsSelected(category.category)) + ")", 1 /* TEXT */),
                        _createElementVNode("div", _hoisted_13, [
                          _createVNode(_component_b_icon, {
                            icon: category.collapsed ? 'chevron-down' : 'chevron-up'
                          }, null, 8 /* PROPS */, ["icon"])
                        ])
                      ], 8 /* PROPS */, _hoisted_11),
                      _createVNode(_component_Collapse, {
                        class: "r-collapse-normal",
                        when: category.collapsed
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("section", _hoisted_14, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.categoryColumns(category.category), (column) => {
                              return (_openBlock(), _createBlock(_component_b_field, {
                                key: column.field
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_checkbox, {
                                    type: "is-black",
                                    modelValue: $data.newSelectedColumns.includes(column.field),
                                    onInput: (event) => $options.toggleColumnSelection(column.field, event.target.checked)
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$parent.getTableColumnLabel(column)), 1 /* TEXT */)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onInput"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */))
                            }), 128 /* KEYED_FRAGMENT */))
                          ])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["when"])
                    ], 64 /* STABLE_FRAGMENT */))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_b_tab_item, null, {
              header: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(_ctx.l('manage-leads-table-settings/lead-questions-tab')), 1 /* TEXT */)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.l('manage-leads-table-settings/select-products')) + ":", 1 /* TEXT */),
                _createElementVNode("div", _hoisted_16, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.productsWithQuestions, (product) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "lead-product-with-questions",
                      key: product._id
                    }, [
                      _createVNode(_component_b_field, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_checkbox, {
                            type: "is-black",
                            modelValue: $options.productQuestionColumnsCheckboxState(product) === "all",
                            indeterminate: $options.productQuestionColumnsCheckboxState(product) === "some",
                            onInput: (event) => $options.toggleAllProductQuestionColumns(product, event.target.checked)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(product.name), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "indeterminate", "onInput"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]))
                  }), 128 /* KEYED_FRAGMENT */)),
                  (_ctx.filtering.products.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createElementVNode("i", null, _toDisplayString(_ctx.l('manage-leads-table-settings/clear-product-filter-to-reveal-all')), 1 /* TEXT */)
                      ]))
                    : _createCommentVNode("v-if", true),
                  ($options.productsWithQuestions.length === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.l('manage-leads-table-settings/no-products-with-questions-found')), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true)
                ]),
                ($options.productsWithQuestions.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.productsWithQuestions, (product, idx) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: "column_select_questions_" + product._id
                        }, [
                          _createElementVNode("div", {
                            class: "column-select-category-collapse",
                            onClick: $event => ($options.toggleProductQuestionsCollapsed(product._id))
                          }, [
                            _createElementVNode("div", _hoisted_21, _toDisplayString(product.name) + " (" + _toDisplayString($options.numProductQuestionColumnsSelected(product)) + ")", 1 /* TEXT */),
                            _createElementVNode("div", _hoisted_22, [
                              _createVNode(_component_b_icon, {
                                icon: $data.collapsedProducts.includes(product._id) ? 'chevron-down' : 'chevron-up'
                              }, null, 8 /* PROPS */, ["icon"])
                            ])
                          ], 8 /* PROPS */, _hoisted_20),
                          _createVNode(_component_Collapse, {
                            class: "r-collapse-normal",
                            when: $data.collapsedProducts.includes(product._id)
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("section", _hoisted_23, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product.templateQuestions, (question) => {
                                  return (_openBlock(), _createBlock(_component_b_field, {
                                    key: question._id
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_b_checkbox, {
                                        type: "is-black",
                                        modelValue: $data.newSelectedColumns.includes("question_" + question._id),
                                        onInput: (event) => $options.toggleColumnSelection("question_" + question._id, event.target.checked)
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(question.description), 1 /* TEXT */)
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onInput"])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */))
                                }), 128 /* KEYED_FRAGMENT */))
                              ])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["when"])
                        ], 64 /* STABLE_FRAGMENT */))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_24, [
      _createElementVNode("div", _hoisted_25, [
        _createVNode(_component_b_button, {
          type: "is-light",
          rounded: "",
          disabled: $data.loading,
          onClick: _cache[3] || (_cache[3] = $event => ($options.close()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.l('generic/close')), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled"])
      ])
    ])
  ]))
}